import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { motion, AnimatePresence } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ParseContent from 'components/shared/ParseContent'
import NavLink from 'components/shared/NavLink'
import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  BlogBinkFilter,
  BlogBinkPosts,
} from '@ubo/blog-bink'

// Images
import ArrowRight from 'img/arrowright.inline.svg'
import Close from 'img/close.inline.svg'

const FilterWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  font-family: ${({ theme }) => theme.font.family.primaryMedium};

  &:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 0px;
    bottom: 0px;
    width: 2000px;
    background-color: ${({ theme }) => theme.color.primary};
  }
`

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Blog: React.FC<BlogProps> = ({ fields }) => {
  const {
    allWpPost,
    allWpCategory,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.blogQueryQuery>(graphql`
    query blogQuery {
      allWpPost(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
      allWpCategory(filter: { name: { nin: "Uncategorized" } }) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges

  return (
    <section className="mb-5 pb-lg-5">
      <BlogBink
        posts={posts as BlogBinkPosts}
        categories={allWpCategory.edges}
        id="blog"
        limit={Number(fields.limit)}
      >
        <div className="container py-5">
          <div className="row justify-content-end">
            <div className="col-xl-8">
              <div className="d-flex flex-column mb-lg-5 pb-lg-5">
                {allWpPost.edges.length > 6 && (
                  <div className="row align-items-center">
                    <div className="d-sm-block d-none">
                      <div className="col-sm-4">
                        <Content
                          content={fields.description}
                          className="me-4"
                        />
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <div className="d-sm-flex d-none">
                        <FilterWrapper className="position-relative px-5 py-lg-4 py-3">
                          <BlogFilters />
                        </FilterWrapper>
                      </div>
                      <div className="d-flex d-sm-none">
                        <MobileFilterHandler categories={allWpCategory.edges} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

const MobileFilterCollapsed = styled.div`
  position: fixed;
  bottom: 0;
  background: ${({ theme }) => theme.color.primary};
  padding: 0.75rem 1rem;
  border-top-left-radius: 36px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
  z-index: 4;
  width: 100%;
  & h2 {
    font-family: ${({ theme }) => theme.font.family.primaryMedium};
    font-size: 18px;
  }

  & span {
    color: ${({ theme }) => theme.color.light};
    font-size: 12px;
  }
`

const MobileFilterExpanded = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.color.primary};
  z-index: 4;
  padding: 1.5rem 1rem;
  overflow-y: scroll;
  border-top-left-radius: 36px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));

  & h2 {
    font-family: ${({ theme }) => theme.font.family.primaryMedium};
    font-size: 24px;
  }

  & button {
    background-color: ${({ theme }) => theme.color.light};
    opacity: 1;
    border-radius: 12px;
    text-align: center !important;
    margin-bottom: 1rem;
    padding: 0.5rem;
    width: 75%;
    margin: 0.5rem auto;
  }
`

interface MobileFilterHandlerProps {
  categories: any
}

const MobileFilterHandler: React.FC<MobileFilterHandlerProps> = ({
  categories,
}) => {
  const [mobileFilterOpen, setMobileFilterOpen] = useState<boolean>(false)

  const mobileFilterAnimations = {
    collapsed: {
      height: '0vh',
    },
    open: {
      height: '30vh',
    },
  }

  const blogBink = useBlogBink()

  const selectedCategories: Array<any> = []

  categories.forEach((category: any) => {
    // @ts-ignore
    if (blogBink.selectedFilters.includes(category.node.id)) {
      selectedCategories.push(category)
    }
  })

  return (
    <>
      <AnimatePresence>
        {!mobileFilterOpen && (
          <MobileFilterCollapsed onClick={() => setMobileFilterOpen(true)}>
            <div className="d-flex justify-content-between align-items-center">
              <h2>Filter op categorie</h2>
              <div className="d-flex flex-column mt-2 me-3">
                <span>Huidige categorie(ën):</span>
                {selectedCategories.length === 0 && <span>- Alles</span>}
                {selectedCategories.map((selectedCategory, index: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <span key={index}>{`- ${selectedCategory.node.name}`}</span>
                ))}
              </div>
            </div>
          </MobileFilterCollapsed>
        )}
      </AnimatePresence>

      {mobileFilterOpen && (
        <MobileFilterExpanded>
          <div className="d-flex justify-content-between">
            <h2>Filter op categorie(ën)</h2>
            <Close
              className="me-3"
              style={{ height: 25, width: 25 }}
              onClick={() => setMobileFilterOpen(false)}
            />
          </div>

          <motion.div
            variants={mobileFilterAnimations}
            initial="collapsed"
            animate="open"
            exit="collapsed"
            transition={{ type: 'spring', damping: 20 }}
            tabIndex={-1}
            style={{ overflowY: 'scroll' }}
            className="d-flex flex-column me-3"
          >
            <div className="d-flex flex-column pt-4 mt-3">
              <BlogFilters />
            </div>
          </motion.div>
        </MobileFilterExpanded>
      )}
    </>
  )
}

const StyledBlogFilter = styled(BlogBinkFilter)<{ selected: boolean }>`
  @media (min-width: 576px) {
    opacity: ${(props) => (props.selected ? 1 : 0.4)};
    color: ${(props) =>
      props.selected ? props.theme.color.light : props.theme.color.dark};
    &:hover {
      @media (min-width: 992px) {
        color: ${({ theme }) => theme.color.light};
        opacity: 1;
      }
    }
  }
  @media (max-width: 575px) {
    color: ${(props) =>
      props.selected ? props.theme.color.primary : props.theme.color.dark};
    border: ${(props) =>
      props.selected ? `2px solid ${props.theme.color.dark}` : 'unset'};
  }
`

interface BlogFiltersProps {}

interface BlogFilterProps {
  node: {
    id: string
    name: string
  }
}

const BlogFilters: React.FC<BlogFiltersProps> = () => {
  const blogBink = useBlogBink()

  return (
    <>
      {blogBink.categories.map((category: BlogFilterProps) => (
        <StyledBlogFilter
          key={category.node.id}
          id={category.node.id}
          selected={blogBink.isSelected(category.node.id)}
          className="me-lg-4 text-start text-md-center"
        >
          {category.node.name}
        </StyledBlogFilter>
      ))}
    </>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <div className="row">
        {blogBink.posts.length > 0 &&
          blogBink.posts.map((post, index) => {
            const { node }: any = post

            return (
              <div key={post.node.id} className="col-sm-6 col-lg-4 mb-5 pb-4">
                <BlogGridPost node={node} index={index} />
              </div>
            )
          })}
        {blogBink.posts.length < 1 && (
          <h2>
            Er is (nog) geen nieuws in deze categorie beschikbaar, onze excuses.
            Probeer het later nog eens.
          </h2>
        )}
      </div>

      {blogBink.showMoreButton && (
        <div className="mt-5 text-center">
          <ButtonPrimary to="/" isCustom>
            <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
          </ButtonPrimary>
        </div>
      )}
    </div>
  )
}

const Label = styled.div`
  right: 30px;
  font-family: ${({ theme }) => theme.font.family.primaryMedium};
  color: ${({ theme }) => theme.color.light};
  background-color: ${({ theme }) => theme.color.primary};
  font-size: 14px;

  & span {
    text-transform: uppercase;
  }

  @media (min-width: 576px) {
    padding: 0.8rem 1.9rem;
  }
  @media (max-width: 575px) {
    padding: 0.5rem 1.6rem;
  }
`

const Image = styled(Plaatjie)`
  z-index: -1;
  @media (min-width: 992px) {
    height: 300px;
  }
  @media (max-width: 991px) {
    height: 290px;
  }
  @media (max-width: 767px) {
    height: 220px;
  }
  @media (max-width: 575px) {
    height: 200px;
  }
`

const Description = styled.div`
  z-index: 2;
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 4px 1px;
  min-height: 171px;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-family: ${({ theme }) => theme.font.family.primaryBold};
    font-size: 18px;
    margin-bottom: 1rem;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    @media (min-width: 1200px) {
      -webkit-line-clamp: 3;
      min-height: 63px;
    }

    @media (max-width: 1199px) {
      -webkit-line-clamp: 2;
      min-height: 42px;
    }

    @media (max-width: 575px) {
      font-size: 14px;
      min-height: 32px;
    }
  }

  & svg {
    width: 17.35px;
    height: 13.81px;
    & path {
      fill: ${({ theme }) => theme.color.primary};
    }
  }

  @media (min-width: 768px) {
    margin-top: -3.5rem;
  }

  @media (max-width: 767px) {
    margin-top: -1.5rem;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 1400px) {
      font-size: 28px;
    }
  }

  & p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    @media (min-width: 1400px) {
      -webkit-line-clamp: 2;
    }
    @media (max-width: 1399px) {
      -webkit-line-clamp: 3;
    }
    @media (max-width: 1199px) {
      -webkit-line-clamp: 4;
    }
    @media (max-width: 991px) {
      -webkit-line-clamp: 3;
    }
    @media (max-width: 767px) {
      -webkit-line-clamp: 5;
    }
    @media (max-width: 575px) {
      -webkit-line-clamp: 3;
    }
  }
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  node: {
    id: string
    title: string
    uri: string
    postdetail: {
      highlightedimage: any
      shortdescription: string
      bannerimage: any
      description: string
    }
    categories: any
  }
  index: number
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node, index }) => {
  const { postdetail } = node

  const [currentIndex, setCurrentIndex] = useState<number | null>(null)

  const current: boolean = index === currentIndex

  const category = node.categories.nodes[0].name

  return (
    <>
      <div className="position-relative">
        <Label className="position-absolute">
          <span>{category}</span>
        </Label>
        <Image image={postdetail.highlightedimage} alt="" />
      </div>
      <motion.div
        onHoverStart={() => setCurrentIndex(index)}
        onHoverEnd={() => setCurrentIndex(null)}
      >
        <NavLink to={node.uri || '/'}>
          <Description className="position-relative p-4 mx-4">
            <h2>{node.title}</h2>
            <Content content={postdetail.shortdescription} />
            <motion.div
              animate={current ? { x: '90%' } : { x: 0 }}
              transition={{
                duration: 0.5,
                type: 'spring',
                stiffness: 40,
              }}
              className="d-flex"
            >
              <ArrowRight className="mt-4" />
            </motion.div>
          </Description>
        </NavLink>
      </motion.div>
    </>
  )
}

export default Blog
